import Image from "next/image";
import errorContent from "data/errorContent";

import { Button } from "components/shared/button";

import {
    BackgroundImageContainer,
    ChickenImage,
    Container,
    Content,
    Decoration,
    Title
} from "./Hero.styled";

import type { HandledStatusCode } from "../ErrorPage";

export function Hero({
    statusCode
}: {
    statusCode: HandledStatusCode;
}): JSX.Element {
    const { header, subtitle, description } = errorContent[statusCode];

    return (
        <Container>
            <BackgroundImageContainer>
                <Image
                    src="/images/404-road.png"
                    alt="achtergrond afbeelding"
                    layout="fill"
                    objectFit="cover"
                    unoptimized
                />
            </BackgroundImageContainer>
            <Content>
                <header>
                    <Decoration>{statusCode}</Decoration>
                    <Title>{header}</Title>
                </header>
                {subtitle && (
                    <p>
                        <strong>{subtitle}</strong>
                    </p>
                )}
                <p>{description}</p>
                <Button href="/" buttonType="primary">
                    Terug naar de homepage
                </Button>
                <ChickenImage>
                    <Image
                        src="/images/chicken-illustration-beige.svg"
                        alt="chichen image"
                        width={200}
                        height={200}
                        unoptimized
                    />
                </ChickenImage>
            </Content>
        </Container>
    );
}
