import Head from "next/head";

import { headerThemeOptions } from "components/layout/Layout";
import ErrorPage from "components/errorPage/ErrorPage";
import Layout from "components/layout/Layout";

export default function Custom404(): JSX.Element {
    return (
        <Layout headerTheme={headerThemeOptions.dark}>
            <Head>
                <title>404 | Mr. Fillet</title>
            </Head>
            <ErrorPage statusCode="404" />
        </Layout>
    );
}
