interface ErrorContent {
    header: string;
    subtitle: string | null;
    description: string;
}

const errorContent: Record<string, ErrorContent> = {
    404: {
        description:
            "Probeer terug te gaan naar de homepage en nogmaals te vinden wat je zoekt. Kom je er niet uit? Neem dan contact met ons op, hopelijk kunnen wij je verder helpen.",
        header: "Oh oh.. Looks like the chicken crossed the road..",
        subtitle:
            "Deze pagina kunnen wij niet meer vinden of heeft nooit bestaan."
    },
    500: {
        description:
            "We kunnen geen verbinding maken. Probeer om de pagina te verversen of kom op een later moment terug.",
        header: "Er is iets misgegaan..",
        subtitle: null
    }
};

export default errorContent;
