import { Hero } from "./hero/Hero";

export type HandledStatusCode = "404" | "500";

export default function ErrorPage({
    statusCode = "404"
}: {
    statusCode: HandledStatusCode;
}): JSX.Element {
    return (
        <>
            <Hero statusCode={statusCode} />
        </>
    );
}
