import styled from "styled-components";

import { media } from "constants/media";

export const Container = styled.section`
    background-color: var(--color-brown-60);
    color: var(--color-beige-40);
    margin-top: -120px;
    overflow: hidden;
    padding: 152px 24px 24px;
    position: relative;

    ${media.mdUp} {
        margin-top: -160px;
        padding: 200px 24px 500px;
    }

    ${media.xlUp} {
        margin-top: -128px;
        padding: 200px 0 280px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    font-size: var(--type-paragraph-font-size);
    gap: 24px;
    line-height: var(--type-paragraph-line-height);
    margin: auto;
    max-width: 1200px;
    position: relative;

    ${media.mdUp} {
        gap: 32px;
    }

    ${media.xlUp} {
        padding-left: 112px;
    }

    > p {
        position: relative;
        z-index: 1;

        ${media.mdUp} {
            max-width: 400px;
        }

        ${media.lgUp} {
            max-width: 681px;
        }
    }

    > a {
        align-self: center;

        ${media.mdUp} {
            align-self: flex-start;
        }
    }
`;

export const Decoration = styled.span`
    color: var(--color-orange-50);
    display: block;
    font-family: var(--font-tertiary);
    font-size: var(--type-heading-3-font-size);
    font-style: normal;
    font-weight: var(--font-weight-normal);
    line-height: var(--type-heading-1-line-height);
    margin: 0 0 8px 35px;
    transform: matrix(1, -0.05, 0.09, 1, 0, 0);

    ${media.mdUp} {
        margin: 0 0 8px;
        transform: matrix(1, -0.05, 0.09, 1, 0, 0);
    }
`;

export const Title = styled.h1`
    font-family: var(--font-secondary);
    font-weight: var(--font-weight-normal);
    max-width: 566px;
    position: relative;
    z-index: 1;
`;

export const BackgroundImageContainer = styled.div`
    height: 236px;
    left: -30px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 419px;

    ${media.lgUp} {
        height: 100%;
        position: absolute;
        width: 100%;
    }
`;

export const ChickenImage = styled.div`
    margin-left: auto;
    transform: scaleX(-1);
`;
